import React from "react";

/*export const PlaceloggMessage = (props) => {
  let vals = props.values ? props.values : {};
  return (
    <FormattedMessage
      id={props.id}
      values={{
        ...{
          b: (...chunks) => <b>{chunks}</b>,
          i: (...chunks) => <i>{chunks}</i>,
          em: (...chunks) => <em>{chunks}</em>,
          br: <br />,
        },
        ...vals,
      }}
    />
  );
};*/

export const fetchJson = (url, setter) => {
  fetch(url)
    .then((response) => {
      return response.text();
    })
    .then((data) => {
      //console.log(data);
      setter(JSON.parse(data));
    })
    .catch((e) => {});
};

export const fetchServerInfo = (host, version, setter) => {
  fetch(
    host +
      "/serverinfo?version=" +
      version +
      "+&useragent=" +
      encodeURIComponent(navigator.userAgent)
  )
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      setter(data);
    })
    .catch((e) => {
      systemFailure(e);
    });
};

export const getTopplaceInfo = (host, id, setter) => {
  fetch(host + "/gettopplaceinfo?topplace_id=" + id)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log(data);
      data.topplaceinfo.options = JSON.parse(data.topplaceinfo.options);
      data.topplaceinfo.properties = JSON.parse(data.topplaceinfo.properties);
      //console.log(data);

      /*let tmp = {};
      let keys = Object.keys(data.topplaceinfo);
      for (let i=0;i<keys.length;i++){
        tmp = {...tmp,[keys[i]]:JSON.parse(data.topplaceinfo[keys[i]])}
      };*/

      if (data.topplaceinfo.properties.validfrom) {
        data.topplaceinfo.properties.validfrom = new Date(
          data.topplaceinfo.properties.validfrom
        );
      } else {
        data.topplaceinfo.properties.validfrom = new Date();
      }
      if (data.topplaceinfo.properties.validuntil) {
        data.topplaceinfo.properties.validuntil = new Date(
          data.topplaceinfo.properties.validuntil
        );
      }

      setter(data.topplaceinfo);
    })
    .catch((e) => {
      systemFailure(e);
    });
};

export const topplacereservation = (host, data, setter) => {
  fetch(host + "/topplacereservation", {
    method: "post",
    headers: new Headers({
      "content-type": "application/json",
    }),
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      setter(data);
    })
    .catch((e) => {
      systemFailure(e);
    });
};

export const fetchTimeslots = (host, topplaceid, date, interval, setter) => {
  fetch(
    host +
      "/timeslots?topplace_id=" +
      topplaceid +
      "&date=" +
      date +
      "&interval=" +
      interval
  )
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      setter([]);
      //console.log(data);
      if (Array.isArray(data.myslots)) {
        for (let i = 0; i < data.myslots.length; i++) {
          //let tmp = new Date(date);
          //tmp.setHours(data.myslots[i].time.split(":")[0] * 1);
          //tmp.setMinutes(data.myslots[i].time.split(":")[1] * 1);
          data.myslots[i].time = new Date(data.myslots[i].datetime);
          data.myslots[i].capacity = data.myslots[i].free;
        }
        setter(data.myslots);
      }
    })
    .catch((e) => {
      systemFailure(e);
    });
};

export const fetchFreeTimeslots = (host, topplaceid, date, peoples, setter) => {
  fetch(
    host +
      "/freetimeslots?topplace_id=" +
      topplaceid +
      "&date=" +
      date +
      "&peoples=" +
      peoples
  )
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      setter([]);
      //console.log(data);
      if (Array.isArray(data.myslots)) {
        for (let i = 0; i < data.myslots.length; i++) {
          data.myslots[i].time = new Date(data.myslots[i].gx);
          data.myslots[i].capacity = data.myslots[i].free;
        }
        setter(data.myslots);
      }
    })
    .catch((e) => {
      systemFailure(e);
    });
};

export const systemFailure = (e) => {
  console.log(e);
};
