import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { Calendar } from "@material-ui/pickers";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
  TextField,
  Box,
  Divider,
  List,
  ListItem,
  Checkbox,
  ButtonGroup,
  Stepper,
  Step,
  StepLabel,
  Link,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { KeyboardDatePicker, DatePicker } from "@material-ui/pickers";
import {
  fetchTimeslots,
  topplacereservation,
  fetchJson,
  getTopplaceInfo,
} from "./utils.js";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#004494",
    },
    secondary: {
      main: "#f7941e",
    },
    accent: {
      main: "#f7941e",
    },
    except: {
      main: "#ff0000",
    },
  },
});

const host = "https://aws.placelogg.com/api/";

let testTimesArray = [];
for (let i = 0; i < 24; i++) {
  testTimesArray.push(i);
}

const days = {
  1: "Montag",
  2: "Dienstag",
  3: "Mittwoch",
  4: "Donnerstag",
  5: "Freitag",
  6: "Samstag",
  0: "Sonntag",
};

const useStyles = makeStyles({
  maincard: {
    position: "relative",
    width: "1000px",
    height: "660px",
    margin: "5%",
  },
  buttongroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardtitle: {},
  cardsubtitle: {},
  confirmButton: {
    width: "200px",
    marginLeft: "50%",
    marginTop: "3%",
  },
  birthday: {
    marginTop: "8px",
    marginBottom: "4px",
    padding: "0px 0px",
  },
  backButton: {
    width: "200px",
    marginLeft: "50%",
    marginTop: "3%",
  },
  timeButton: {
    marginTop: "3%",
  },
  picktimeButton: {
    margin: "5%",
    width: "90%",
  },
  innercard: {
    height: "420px",
    overflow: "auto",
    maxWidth: "1000px",
  },
  blockbutton: {
    position: "absolute",
    top: "510px",
    left: "25px",
  },
});

const steps = { first: 0, second: 1, third: 2, fourth: 3 };

function App() {
  const formatDate = (dat) => {
    return (
      dat.getFullYear() +
      "-" +
      (dat.getMonth() + 1 < 10
        ? "0" + (dat.getMonth() + 1)
        : dat.getMonth() + 1) +
      "-" +
      dat.getDate()
    );
  };

  const history = useHistory();
  const classes = useStyles();
  const [topplaceinfo, setTopplaceinfo] = useState({
    properties: { validfrom: new Date() },
  });
  const [interval, setInterval] = useState(60);
  //const [date, setDate] = useState(formatDate(new Date()));

  const [step, setStep] = useState("first");
  const [dayTime, setDayTime] = useState("Nachmittag");
  const [locked, setLocked] = useState("");
  const [timeslots, setTimeslots] = useState([]);
  const [agb, setAgb] = useState(false);
  const [reservationId, setReservationId] = useState("");
  const [topplaceid, setTopplaceid] = useState(
    "3502A941-FD13-45E5-8478-A4A7F7BD40B9"
  );

  const defaultContent = {
    date: topplaceinfo.properties.validfrom,
    birthday: "",
  };
  const [content, setContent] = useState(defaultContent);

  const reservation = () => {
    let result = {
      timefrom: content.date,
      timeuntil: new Date(content.date.getTime() + interval * 60000),
      topplace_id: topplaceid,
      language: "de",
      guests: 1,
      properties: {},
      typ: "reservation",
      reservation_id: reservationId ? reservationId : undefined,
    };
    topplacereservation(host, result, (data) => {
      if (data.code === 200) {
        setStep("third");
        setReservationId(data.reservation_id);
        setLocked(content.date);
      } else {
        setAgb(false);
        setStep("first");
        setReservationId("");
        if (
          new Date().getTime() < topplaceinfo.properties.validfrom.getTime()
        ) {
          setContent({ ...content, date: topplaceinfo.properties.validfrom });
        } else {
          setContent({ ...content, date: new Date() });
        }
        if (data.code === 405) {
          alert(
            "Leider ist dieser Zeitraum schon überbucht, \nbitte wählen Sie einen anderen Termin."
          );
        } else {
          alert(
            "Leider ist bei Ihrer Buchung ein Fehler aufgetreten, \nbitte versuchen Sie es in wenigen Minuten noch einmal."
          );
        }
      }
    });
  };

  const submit = () => {
    //e.preventDefault();
    console.log(content);
    let result = {
      reservation_id: reservationId,
      timefrom: content.date,
      timeuntil: new Date(content.date.getTime() + interval * 60000),
      label: content.firstname + " " + content.lastname,
      topplace_id: topplaceid,
      firstname: content.firstname,
      lastname: content.lastname,
      //user_hash: data.user_hash,
      email: content.email,
      phone: content.phone,
      street: content.street,
      city: content.city,
      postcode: content.postcode,
      language: "de",
      pwd:
        content.birthday.getDate() +
        "." +
        (content.birthday.getMonth() + 1) +
        "." +
        content.birthday.getFullYear(),

      guests: 1,
      //status: "teststatus",
      properties: {},
      remark: content.remark,
      typ: "booking",
    };

    /*let reservation = timeslot.time;
    console.log(timeslot);
    console.log(reservation);*/

    topplacereservation(host, result, (data) => {
      setAgb(false);
      setStep("first");
      setReservationId("");

      if (data.code === 200) {
        alert(
          "Vielen Dank für Ihre Buchung. \nSie erhalten eine Emailbestätigung an die angegebene Adresse. \n(Bitte kontrollieren Sie evtl. ihren SPAM-Ordner.)"
        );
      } else {
        alert(
          "Leider ist bei Ihrer Buchung ein Fehler aufgetreten, \nbitte versuchen Sie es in wenigen Minuten noch einmal."
        );
      }
    });
  };
  const updateTopplaceinfo = (info) => {
    setTopplaceinfo(info);
    if (info.properties.reservationinterval)
      setInterval(info.properties.reservationinterval * 1);

    if (new Date().getTime() < info.properties.validfrom.getTime()) {
      setContent({ ...content, date: info.properties.validfrom });
    }
  };
  //console.log(topplaceinfo);
  let tpid = "";

  if (history) {
    if (history.location) {
      if (history.location.search) {
        if (history.location.search.indexOf("?id=") > -1) {
          let tpid = history.location.search.replace("?id=", "");

          if (topplaceid !== tpid) {
            setTopplaceid(tpid);
          }
        }
      }
    }
  }

  useEffect(() => {
    //getServerInfo(host, version, setServerInfo);
    if (topplaceid) {
      getTopplaceInfo(host, topplaceid, updateTopplaceinfo);
    }
  }, [topplaceid]);

  useEffect(() => {
    //getServerInfo(host, version, setServerInfo);
    if (topplaceid && content.date && interval) {
      //console.log(topplaceid, content.date, interval);
      fetchTimeslots(
        host,
        topplaceid,
        formatDate(content.date),
        interval,
        setTimeslots
      );
    }
  }, [topplaceid, content.date, interval]);
  //console.log(topplaceinfo);
  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <header className="App-header">
          <React.Fragment>
            {step === "first" && (
              <Card className={classes.maincard} elevation="3">
                <CardContent>
                  <Stepper
                    activeStep={steps[step]}
                    style={{ paddingTop: "0px" }}
                  >
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                  </Stepper>
                  <Typography
                    className={classes.cardtitle}
                    align="center"
                    variant="h4"
                  >
                    {topplaceinfo.properties.name
                      ? topplaceinfo.properties.name
                      : "Corona-Test"}
                  </Typography>
                  <Typography
                    className={classes.cardsubtitle}
                    align="center"
                    variant="subtitle1"
                  >
                    Bitte wählen Sie ein Datum
                  </Typography>
                  <Card className={classes.innercard}>
                    <Calendar
                      minDate={
                        topplaceinfo
                          ? topplaceinfo.properties.validfrom
                          : new Date()
                      }
                      shouldDisableDate={(day) => {
                        if (
                          day.getTime() - new Date().getTime() >
                          86400 * 1000 * 21
                        )
                          return true;
                        if (topplaceinfo) {
                          if (
                            Array.isArray(topplaceinfo.properties.closingtimes)
                          ) {
                            let d1 = 0;
                            let d = day.getDay();
                            if (d === 0) d1 = 6;
                            if (d === 1) d1 = 0;
                            if (d === 2) d1 = 1;
                            if (d === 3) d1 = 2;
                            if (d === 4) d1 = 3;
                            if (d === 5) d1 = 4;
                            if (d === 6) d1 = 5;
                            //console.log(d1);

                            return !topplaceinfo.properties.closingtimes[d1]
                              .open;
                          }
                        }
                        return true;
                      }}
                      width="100%"
                      disablePast
                      date={content.date}
                      onChange={(dat) => setContent({ ...content, date: dat })}
                    />
                    <Grid container>
                      <Grid item xs={4} style={{ textAlign: "center" }}>
                        <a href="https://apps.apple.com/de/app/placelogg/id1542176850">
                          <img
                            style={{ width: 90 }}
                            src={"https://image.placelogg.com/applestore.png"}
                          />
                        </a>
                      </Grid>
                      <Grid item xs={4} style={{ textAlign: "center" }}>
                        <img
                          style={{ height: 90, width: 90 }}
                          src={
                            "https://image.placelogg.com/" +
                            topplaceid +
                            "/clickandmeet.png"
                          }
                        />
                        <br />
                        Click&Meet mit der placelogg-App
                      </Grid>
                      <Grid item xs={4} style={{ textAlign: "center" }}>
                        <a href="https://play.google.com/store/apps/details?id=berlin.slb.placelogg">
                          <img
                            style={{ width: 110, marginTop: 20 }}
                            src={"https://image.placelogg.com/playstore.png"}
                          />
                        </a>
                      </Grid>
                    </Grid>
                  </Card>
                </CardContent>
                <CardActions>
                  <Button
                    style={{ color: "white" }}
                    disabled
                    className={classes.backButton}
                    color="grey"
                    onClick={() => {
                      setStep("first");
                    }}
                  >
                    Zurück
                  </Button>
                  <Button
                    variant="outlined"
                    className={classes.confirmButton}
                    color="primary"
                    onClick={() => setStep("second")}
                  >
                    Weiter
                  </Button>
                </CardActions>
              </Card>
            )}
            {step === "second" && (
              <Card className={classes.maincard} elevation="3">
                <CardContent>
                  <Stepper
                    activeStep={steps[step]}
                    style={{ paddingTop: "0px" }}
                  >
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                  </Stepper>
                  <Typography
                    className={classes.cardtitle}
                    align="center"
                    variant="h4"
                  >
                    {topplaceinfo.properties.name
                      ? topplaceinfo.properties.name
                      : "Corona-Test"}
                  </Typography>
                  <Typography
                    className={classes.cardsubtitle}
                    align="center"
                    variant="subtitle1"
                  >
                    {days[content.date.getDay()]},{" "}
                    {content.date.toLocaleDateString()}
                  </Typography>
                  <Card className={classes.innercard}>
                    <div className={classes.buttongroup}>
                      <ButtonGroup>
                        <Button
                          fullWidth
                          variant="outlined"
                          className={classes.timeButton}
                          color="primary"
                          style={{
                            backgroundColor:
                              dayTime === "Morgen" ? "#b3d5ff" : "white",
                          }}
                          onClick={() => setDayTime("Morgen")}
                        >
                          Morgen
                        </Button>
                        <Button
                          fullWidth
                          color="primary"
                          variant="outlined"
                          className={classes.timeButton}
                          style={{
                            backgroundColor:
                              dayTime === "Nachmittag" ? "#b3d5ff" : "white",
                          }}
                          onClick={() => setDayTime("Nachmittag")}
                        >
                          Nachmittag
                        </Button>
                        <Button
                          fullWidth
                          color="primary"
                          variant="outlined"
                          className={classes.timeButton}
                          style={{
                            backgroundColor:
                              dayTime === "Abend" ? "#b3d5ff" : "white",
                          }}
                          onClick={() => setDayTime("Abend")}
                        >
                          Abend
                        </Button>
                      </ButtonGroup>
                    </div>
                    <Grid container>
                      {dayTime === "Morgen" &&
                        timeslots
                          .filter((o) => {
                            let tmp = o.time;
                            return tmp.getHours() < 12;
                          })
                          .map((o, i) => {
                            let tmp = o.time;
                            return (
                              <Grid item xs={3}>
                                <Button
                                  disabled={
                                    o.free * 1 < 1 ||
                                    o.time.getTime() < new Date().getTime()
                                  }
                                  style={{
                                    backgroundColor:
                                      content.time ===
                                      "" +
                                        tmp.getHours() +
                                        ":" +
                                        (tmp.getMinutes() < 10
                                          ? "0" + tmp.getMinutes()
                                          : tmp.getMinutes())
                                        ? "#b3d5ff"
                                        : "white",
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  className={classes.picktimeButton}
                                  onClick={() => {
                                    console.log(locked);
                                    console.log(tmp);
                                    setContent({
                                      ...content,
                                      time:
                                        "" +
                                        tmp.getHours() +
                                        ":" +
                                        (tmp.getMinutes() < 10
                                          ? "0" + tmp.getMinutes()
                                          : tmp.getMinutes()),
                                      date: tmp,
                                    });
                                  }}
                                >
                                  <Grid container>
                                    <Grid item xs={3} />
                                    <Grid item xs={6}>
                                      {"" +
                                        tmp.getHours() +
                                        ":" +
                                        (tmp.getMinutes() < 10
                                          ? "0" + tmp.getMinutes()
                                          : tmp.getMinutes())}
                                    </Grid>
                                    <Grid item xs={3}>
                                      {reservationId &&
                                      (locked
                                        ? locked.getTime() === tmp.getTime()
                                        : false) ? (
                                        <LockIcon fontSize="small" />
                                      ) : (
                                        ""
                                      )}
                                    </Grid>
                                  </Grid>
                                </Button>
                              </Grid>
                            );
                          })}
                      {dayTime === "Nachmittag" &&
                        timeslots
                          .filter((o) => {
                            let tmp = o.time;
                            return tmp.getHours() >= 12 && tmp.getHours() < 17;
                          })
                          .map((o, i) => {
                            let tmp = o.time;
                            return (
                              <Grid item xs={3}>
                                <Button
                                  disabled={
                                    o.free * 1 < 1 ||
                                    o.time.getTime() < new Date().getTime()
                                  }
                                  style={{
                                    backgroundColor:
                                      content.time ===
                                      "" +
                                        tmp.getHours() +
                                        ":" +
                                        (tmp.getMinutes() < 10
                                          ? "0" + tmp.getMinutes()
                                          : tmp.getMinutes())
                                        ? "#b3d5ff"
                                        : "white",
                                  }}
                                  color="primary"
                                  variant="outlined"
                                  className={classes.picktimeButton}
                                  onClick={() => {
                                    setContent({
                                      ...content,
                                      time:
                                        "" +
                                        tmp.getHours() +
                                        ":" +
                                        (tmp.getMinutes() < 10
                                          ? "0" + tmp.getMinutes()
                                          : tmp.getMinutes()),
                                      date: tmp,
                                    });
                                  }}
                                >
                                  <Grid container>
                                    <Grid item xs={3} />
                                    <Grid item xs={6}>
                                      {"" +
                                        tmp.getHours() +
                                        ":" +
                                        (tmp.getMinutes() < 10
                                          ? "0" + tmp.getMinutes()
                                          : tmp.getMinutes())}
                                    </Grid>
                                    <Grid item xs={3}>
                                      {reservationId &&
                                      (locked
                                        ? locked.getTime() === tmp.getTime()
                                        : false) ? (
                                        <LockIcon fontSize="small" />
                                      ) : (
                                        ""
                                      )}
                                    </Grid>
                                  </Grid>
                                </Button>
                              </Grid>
                            );
                          })}
                      {dayTime === "Abend" &&
                        timeslots
                          .filter((o) => {
                            let tmp = o.time;
                            return tmp.getHours() >= 17;
                          })
                          .map((o, i) => {
                            let tmp = o.time;
                            return (
                              <Grid item xs={3}>
                                <Button
                                  disabled={
                                    o.free * 1 < 1 ||
                                    o.time.getTime() < new Date().getTime()
                                  }
                                  style={{
                                    backgroundColor:
                                      content.time ===
                                      "" +
                                        tmp.getHours() +
                                        ":" +
                                        (tmp.getMinutes() < 10
                                          ? "0" + tmp.getMinutes()
                                          : tmp.getMinutes())
                                        ? "#b3d5ff"
                                        : "white",
                                  }}
                                  color="primary"
                                  variant="outlined"
                                  className={classes.picktimeButton}
                                  onClick={() => {
                                    setContent({
                                      ...content,
                                      time:
                                        "" +
                                        tmp.getHours() +
                                        ":" +
                                        (tmp.getMinutes() < 10
                                          ? "0" + tmp.getMinutes()
                                          : tmp.getMinutes()),
                                      date: tmp,
                                    });
                                  }}
                                >
                                  <Grid container>
                                    <Grid item xs={3} />
                                    <Grid item xs={6}>
                                      {"" +
                                        tmp.getHours() +
                                        ":" +
                                        (tmp.getMinutes() < 10
                                          ? "0" + tmp.getMinutes()
                                          : tmp.getMinutes())}
                                    </Grid>
                                    <Grid item xs={3}>
                                      {reservationId &&
                                      (locked
                                        ? locked.getTime() === tmp.getTime()
                                        : false) ? (
                                        <LockIcon fontSize="small" />
                                      ) : (
                                        ""
                                      )}
                                    </Grid>
                                  </Grid>
                                </Button>
                              </Grid>
                            );
                          })}
                    </Grid>
                    {/*<CardActions>
                      <Button
                        variant="outlined"
                        disabled={!content.time || locked !== ""}
                        className={classes.blockbutton}
                        color="grey"
                        onClick={() => {
                          reservation();
                        }}
                      >
                        Termin blockieren
                      </Button>
                    </CardActions>*/}
                  </Card>
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    className={classes.backButton}
                    color="grey"
                    onClick={() => {
                      setStep("first");
                    }}
                  >
                    Zurück
                  </Button>
                  <Button
                    disabled={!content.time}
                    variant="outlined"
                    className={classes.confirmButton}
                    color="primary"
                    onClick={() => reservation()}
                  >
                    Weiter
                  </Button>
                </CardActions>
              </Card>
            )}
            {step === "third" && (
              <Card className={classes.maincard} elevation="3">
                <CardContent>
                  <Stepper
                    activeStep={steps[step]}
                    style={{ paddingTop: "0px" }}
                  >
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                  </Stepper>
                  <Typography
                    className={classes.cardtitle}
                    align="center"
                    variant="h4"
                  >
                    {topplaceinfo.properties.name
                      ? topplaceinfo.properties.name
                      : "Corona-Test"}
                  </Typography>
                  <Typography
                    className={classes.cardsubtitle}
                    align="center"
                    variant="subtitle1"
                  >
                    {days[content.date.getDay()]},{" "}
                    {content.date.toLocaleDateString()}, {content.time} Uhr
                  </Typography>
                  <Card className={classes.innercard}>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={4}>
                          <TextField
                            value={content.firstname || ""}
                            variant="outlined"
                            required={true}
                            margin="dense"
                            id="firstname"
                            label="Vorname"
                            //type="test"
                            fullWidth
                            onChange={(e) =>
                              setContent({
                                ...content,
                                ...{ firstname: e.target.value },
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            value={content.lastname || ""}
                            variant="outlined"
                            required={true}
                            margin="dense"
                            id="lastname"
                            label="Name"
                            //type="test"
                            fullWidth
                            onChange={(e) =>
                              setContent({
                                ...content,
                                ...{ lastname: e.target.value },
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <DatePicker
                            className={classes.birthday}
                            //style={{margin:"0px",padding:"0px 0px"}}
                            margin="dense"
                            fullWidth
                            required={true}
                            inputVariant="outlined"
                            disableFuture
                            openTo="year"
                            inputProps={{ style: { padding: "10.5px 14px" } }}
                            views={["year", "month", "date"]}
                            format="dd.MM.yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Geburtsdatum"
                            value={content.birthday || null}
                            onChange={(e) =>
                              setContent({ ...content, ...{ birthday: e } })
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            value={content.email || ""}
                            variant="outlined"
                            required={true}
                            margin="dense"
                            id="email"
                            label="E-Mail"
                            //type="test"
                            fullWidth
                            onChange={(e) =>
                              setContent({
                                ...content,
                                ...{ email: e.target.value },
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            value={content.phone || ""}
                            variant="outlined"
                            required={true}
                            margin="dense"
                            id="phone"
                            label="Telefonnummer"
                            //type="test"
                            fullWidth
                            onChange={(e) =>
                              setContent({
                                ...content,
                                ...{ phone: e.target.value },
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            value={content.street || ""}
                            variant="outlined"
                            required={true}
                            margin="dense"
                            id="street"
                            label="Straße und Hausnummer"
                            //type="test"
                            fullWidth
                            onChange={(e) =>
                              setContent({
                                ...content,
                                ...{ street: e.target.value },
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            value={content.postcode || ""}
                            variant="outlined"
                            required={true}
                            margin="dense"
                            id="place"
                            label="PLZ"
                            //type="test"
                            fullWidth
                            onChange={(e) =>
                              setContent({
                                ...content,
                                ...{ postcode: e.target.value },
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            value={content.city || ""}
                            variant="outlined"
                            required={true}
                            margin="dense"
                            id="place"
                            label="Ort"
                            //type="test"
                            fullWidth
                            onChange={(e) =>
                              setContent({
                                ...content,
                                ...{ city: e.target.value },
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            multiline
                            rows={4}
                            value={content.remark || ""}
                            variant="outlined"
                            margin="dense"
                            id="remark"
                            label="Hinweise"
                            //type="test"
                            fullWidth
                            onChange={(e) =>
                              setContent({
                                ...content,
                                ...{ remark: e.target.value },
                              })
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography style={{ padding: "9px 0px" }}>
                            Der Termin ist 15 Minuten für Sie freigehalten.
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    className={classes.backButton}
                    color="grey"
                    onClick={() => {
                      setStep("second");
                    }}
                  >
                    Zurück
                  </Button>
                  <Button
                    disabled={
                      !(
                        content.firstname &&
                        content.lastname &&
                        content.email &&
                        content.phone &&
                        content.street &&
                        content.postcode &&
                        content.city &&
                        content.birthday
                      )
                    }
                    variant="outlined"
                    className={classes.confirmButton}
                    color="primary"
                    onClick={() => setStep("fourth")}
                  >
                    Weiter
                  </Button>
                </CardActions>
              </Card>
            )}
            {step === "fourth" && (
              <Card className={classes.maincard} elevation="3">
                <CardContent>
                  <Stepper
                    activeStep={steps[step]}
                    style={{ paddingTop: "0px" }}
                  >
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                  </Stepper>
                  <Typography
                    className={classes.cardtitle}
                    align="center"
                    variant="h4"
                  >
                    {topplaceinfo.properties.name
                      ? topplaceinfo.properties.name
                      : "Corona-Test"}
                  </Typography>
                  <Typography
                    className={classes.cardsubtitle}
                    align="center"
                    variant="subtitle1"
                  >
                    Sind diese Angaben korrekt?
                  </Typography>
                  <Card className={classes.innercard}>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={5}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography variant="h6">
                                Persönliche Daten
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>{content.firstname}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>{content.lastname}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                {"geboren " +
                                  content.birthday.getDate() +
                                  "." +
                                  (content.birthday.getMonth() + 1) +
                                  "." +
                                  content.birthday.getFullYear()}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>{content.email}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>{content.phone}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>{content.street}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>{content.place}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>{content.remark}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={5}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography variant="h6">Test-Termin</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                {days[content.date.getDay()]}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                {content.date.getDate() +
                                  "." +
                                  (content.date.getMonth() + 1) +
                                  "." +
                                  content.date.getFullYear()}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>{content.time + " Uhr"}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={1}>
                          <Checkbox
                            checked={agb}
                            onChange={() => setAgb(!agb)}
                            color="primary"
                          />
                        </Grid>
                        <Grid item xs={11}>
                          <Typography style={{ padding: "9px 0px" }}>
                            Ich habe die{" "}
                            <Link
                              href={topplaceinfo.properties.agblink}
                              target="_blank"
                            >
                              AGBs
                            </Link>{" "}
                            gelesen und akzeptiere sie
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    className={classes.backButton}
                    color="grey"
                    onClick={() => {
                      setStep("third");
                    }}
                  >
                    Zurück
                  </Button>
                  <Button
                    disabled={!agb}
                    variant="outlined"
                    className={classes.confirmButton}
                    color="primary"
                    onClick={() => submit()}
                  >
                    Termin buchen
                  </Button>
                </CardActions>
              </Card>
            )}
            {/*<Button
              onClick={() => {
                console.log(content);
                console.log(reservationId);
                console.log(locked);
              }}
            >
              test
            </Button>*/}
          </React.Fragment>
        </header>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
